import React from 'react';
import Typewriter from 'typewriter-effect';

function TypewriterText() {
  return (
    <Typewriter
      options={{ loop: true }}
      onInit={typewriter => {
        typewriter
          .changeDelay(50)
          .typeString('QA Engineer')
          .start()
          .pauseFor(1000)
          .deleteAll(20)
          .typeString('Cypress enthusiast')
          .start()
          .pauseFor(1000)
          .deleteAll(20)
          .typeString('Photographer')
          .start()
          .pauseFor(1000)
          .deleteAll(20);
      }}
    />
  );
}

export default TypewriterText;
