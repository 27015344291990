import React from 'react';

import PortfolioLayout from './PortfolioLayout';

const Portfolio: React.FunctionComponent = () => (
  <PortfolioLayout
    images={[
      {
        src: 'img/portfolio/photo/01.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/01.jpg',
        width: '1294',
        height: '862',
      },
      {
        src: 'img/portfolio/photo/02.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/02.jpg',
        width: '1523',
        height: '945',
      },
      {
        src: 'img/portfolio/photo/03.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/03.jpg',
        width: '897',
        height: '1112',
      },
      {
        src: 'img/portfolio/photo/04.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/04.jpg',
        width: '1197',
        height: '1009',
      },
      {
        src: 'img/portfolio/photo/05.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/05.jpg',
        width: '970',
        height: '1455',
      },
      {
        src: 'img/portfolio/photo/06.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/06.jpg',
        width: '1469',
        height: '979',
      },
      {
        src: 'img/portfolio/photo/07.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/07.jpg',
        width: '980',
        height: '1469',
      },
      {
        src: 'img/portfolio/photo/08.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/08.jpg',
        width: '1257',
        height: '838',
      },
      {
        src: 'img/portfolio/photo/09.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/09.jpg',
        width: '983',
        height: '1475',
      },
      {
        src: 'img/portfolio/photo/10.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/10.jpg',
        width: '961',
        height: '1442',
      },
      {
        src: 'img/portfolio/photo/11.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/11.jpg',
        width: '1232',
        height: '821',
      },
      {
        src: 'img/portfolio/photo/12.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/12.jpg',
        width: '1411',
        height: '941',
      },
      {
        src: 'img/portfolio/photo/13.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/13.jpg',
        width: '1270',
        height: '847',
      },
      {
        src: 'img/portfolio/photo/14.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/14.jpg',
        width: '1021',
        height: '1532',
      },
      {
        src: 'img/portfolio/photo/15.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/15.jpg',
        width: '1502',
        height: '1001',
      },
      {
        src: 'img/portfolio/photo/16.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/16.jpg',
        width: '1381',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/17.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/17.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/18.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/18.jpg',
        width: '1720',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/19.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/19.jpg',
        width: '1518',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/20.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/20.jpg',
        width: '1261',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/21.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/21.jpg',
        width: '720',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/22.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/22.jpg',
        width: '1545',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/23.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/23.jpg',
        width: '1394',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/24.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/24.jpg',
        width: '1516',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/25.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/25.jpg',
        width: '1080',
        height: '1536',
      },
      {
        src: 'img/portfolio/photo/26.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/26.jpg',
        width: '1080',
        height: '1744',
      },
      {
        src: 'img/portfolio/photo/27.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/27.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/28.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/28.jpg',
        width: '1080',
        height: '1155',
      },
      {
        src: 'img/portfolio/photo/29.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/29.jpg',
        width: '699',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/30.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/30.jpg',
        width: '1466',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/31.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/31.jpg',
        width: '1920',
        height: '993',
      },
      {
        src: 'img/portfolio/photo/32.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/32.jpg',
        width: '780',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/33.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/33.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/34.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/34.jpg',
        width: '772',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/35.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/35.jpg',
        width: '667',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/36.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/36.jpg',
        width: '1820',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/37.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/37.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/38.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/38.jpg',
        width: '1480',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/39.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/39.jpg',
        width: '667',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/40.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/40.jpg',
        width: '1685',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/41.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/41.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/photo/42.jpg',
        thumbnail: 'img/portfolio/photo/thumbnail/42.jpg',
        width: '1620',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/01.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/01.jpg',
        width: '1516',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/02.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/02.jpg',
        width: '1516',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/03.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/03.jpg',
        width: '1485',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/04.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/04.jpg',
        width: '763',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/05.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/05.jpg',
        width: '843',
        height: '1080',
      },
      {
        src: 'img/portfolio/drawing/06.jpg',
        thumbnail: 'img/portfolio/drawing/thumbnail/06.jpg',
        width: '1517',
        height: '1081',
      },
      {
        src: 'img/portfolio/painting/01.jpg',
        thumbnail: 'img/portfolio/painting/thumbnail/01.jpg',
        width: '715',
        height: '972',
      },
      {
        src: 'img/portfolio/painting/02.jpg',
        thumbnail: 'img/portfolio/painting/thumbnail/02.jpg',
        width: '820',
        height: '984',
      },
      {
        src: 'img/portfolio/painting/03.jpg',
        thumbnail: 'img/portfolio/painting/thumbnail/03.jpg',
        width: '1005',
        height: '998',
      },
      {
        src: 'img/portfolio/painting/04.jpg',
        thumbnail: 'img/portfolio/painting/thumbnail/04.jpg',
        width: '803',
        height: '986',
      },
      {
        src: 'img/portfolio/painting/05.jpg',
        thumbnail: 'img/portfolio/painting/thumbnail/05.jpg',
        width: '834',
        height: '1029',
      },
    ]}
  />
);

export default Portfolio;
