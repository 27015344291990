import React from 'react';

import NavigationLayout from './NavigationLayout';

const Navigation: React.FunctionComponent = () => (
  <NavigationLayout
    items={[
      {
        id: '1',
        title: 'Home',
        linkTo: '#home',
      },
      {
        id: '2',
        title: 'About me',
        linkTo: '#about',
      },
      {
        id: '3',
        title: 'Skills',
        linkTo: '#skills',
      },
      {
        id: '4',
        title: 'Achievements',
        linkTo: '#achievements',
      },
      {
        id: '5',
        title: 'Portfolio',
        linkTo: '#portfolio',
      },
      {
        id: '6',
        title: 'Contact',
        linkTo: '#contact',
      },
    ]}
  />
)

export default Navigation;