import React from 'react';
import { Link } from 'react-router-dom';

type Item = {
  id?: string;
  title?: string;
  linkTo?: string;
};

type Props = {
  items: Item[];
};

const NavigationLayout: React.FunctionComponent<Props> = ({ items }) => (
  <div className="container">
    <div className="row">
      <nav
        id="navbar"
        className="navbar nav-scroll navbar-expand-lg bg-light-gray"
      >
        <div className="container px-2 py-lg-0 py-4">
          <Link to="/" className="navbar-brand fw-bold text-dark">
            filipsnopek.sk
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navNavbar"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src="/img/icons/list.svg" alt="Menu" width="24" height="24" />
          </button>
          <div className="collapse navbar-collapse" id="navNavbar">
            <ul className="navbar-nav ms-auto">
              {items.map(item => (
                <li className="nav-item pr-4" key={item.id}>
                  <a className="nav-link" href={item.linkTo}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
);

export default NavigationLayout;
