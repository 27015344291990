import React from 'react';

type SocialIcons = {
  id: string;
  icon: string;
  url: string;
};

type Props = {
  social: SocialIcons[];
};

const ButtonSocial: React.FunctionComponent<Props> = ({ social }) => (
  <div className="social-links text-lg-start text-center mb-4">
    {social.map(item => (
      <a key={item.id} href={item.url} target="_blank" rel="noopener noreferrer">
        <img src={`/img/icons/${item.icon}.svg`} alt={item.icon} width="20" height="20" />
      </a>
    ))}
  </div>
);

export default ButtonSocial;
