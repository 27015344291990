import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

type Image = {
  src: string;
  thumbnail: string;
  width: string;
  height: string;
};

type Props = {
  images: Image[];
};

const PortfolioLayout: React.FunctionComponent<Props> = ({ images }) => (
  <section id="portfolio" data-bs-target="#navNavbar">
    <div className="container">
      <div className="row">
        <div className="text-center pb-5">
          <h5 className="fw-bolder section-title">Portfolio</h5>
        </div>
      </div>
      <Gallery>
        <div className="grid" data-colcade="columns: .grid-col, items: .grid-item">
          <div className="grid-col grid-col--1" />
          <div className="grid-col grid-col--2" />
          <div className="grid-col grid-col--3" />
          <div className="grid-col grid-col--4" />

          {images.map(image => (
            <div key={image.src} className="grid-item">
              <div>
                <Item original={image.src} width={image.width} height={image.height} thumbnail={image.thumbnail}>
                  {({ ref, open }) => (
                    <img
                      loading="lazy"
                      id={image.src}
                      ref={ref as React.MutableRefObject<any>}
                      onClick={open}
                      src={image.thumbnail}
                      alt="Portfolio"
                      width="1"
                      height="1"
                    />
                  )}
                </Item>
              </div>
            </div>
          ))}
        </div>
      </Gallery>
    </div>
  </section>
);

export default PortfolioLayout;
