import React from 'react';

import AchievementsLayout from './AchievementsLayout';

function Achievements() {
  return (
    <AchievementsLayout
      achievements={[
        {
          id: '1',
          title: 'ISTQB Certificate',
          description: 'Advanced level - Technical Test Analyst',
          month: 'August',
          year: '2018',
          textLeft: true,
        },
        {
          id: '2',
          title: 'ISTQB Certificate',
          description: 'Foundation level',
          month: 'June',
          year: '2016',
          textLeft: false,
        },
      ]}
    />
  );
}

export default Achievements;
