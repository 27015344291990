import React from 'react';

function AboutText() {
  return (
    <div className="col-lg-6 col-md-12 mb-5 text-start">
      <p>
        I&apos;ve started in AutoVision Slovakia company, where I&apos;ve been executing specific
        tasks as IT-Tester. They included design and creation of test cases based on the plan and
        documentation, execution of manual tests (smoke, regression, system integration), recording
        and analyzing the bugs detected, reporting. After that I worked in Ferratum company, where I
        was in charge of testing a system, which serves for providing various bank products.
      </p>
      <p>
        Currently I&apos;m working as <strong>QA Engineer</strong> in{' '}
        <strong>Erste Group IT</strong> company. Here I&apos;m in charge of testing an internet
        banking, also known as <strong>George</strong>.
      </p>
      <p>
        As far as free time is concerned, I am particularly interested in painting, photography and
        drawing (and in really old times 3d graphics). Part of my work can be seen in my{' '}
        <strong>Portfolio</strong>.
      </p>
      <div className="text-lg-start text-center">
        <a href="#portfolio" className="btn white">
          Portfolio
        </a>
      </div>
    </div>
  );
}

export default AboutText;
