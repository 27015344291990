import React from 'react';

const PageNotFound: React.FunctionComponent = () => (
  <section id="not-found" className="bg-light-gray" data-bs-target="#navNavbar">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12 order-2 order-lg-1 pt-5">
          <h1>404</h1>
          <h2 className="text-uppercase">Page not found</h2>
          <a href="/" className="btn white">
            Back to filipsnopek.sk
          </a>
        </div>
        <div className="col-lg-6 col-md-12 order-1 order-lg-2 text-end">
          <img src="img/404.webp" alt="Page not found" className="pt-5"/>
        </div>
      </div>
    </div>
  </section>
);

export default PageNotFound;
