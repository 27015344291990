import React from 'react';

type Achievement = {
  id: string;
  title: string;
  description: string;
  month: string;
  year: string;
  textLeft: boolean;
};

type Props = {
  achievements: Achievement[];
};

const AchievementsLayout: React.FunctionComponent<Props> = ({ achievements }) => (
  <section id="achievements" data-bs-target="#navNavbar">
    <div className="container">
      <div className="row">
        <div className="text-center pb-5">
          <h5 className="fw-bolder section-title">Achievements</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline">
            {achievements.map(achievement => (
              <div key={achievement.id} className="timeline">
                <div className="icon" />
                <div className="date-content">
                  <div className="date-outer">
                    <span className="date">
                      <span className="month">{achievement.month}</span>
                      <span className="year">{achievement.year}</span>
                    </span>
                  </div>
                </div>
                <div className="timeline-content">
                  <h5
                    className={achievement.textLeft ? 'text-lg-start title' : 'text-lg-end title'}
                  >
                    {achievement.title}
                  </h5>
                  <h6 className={achievement.textLeft ? 'text-lg-start' : 'text-lg-end'}>
                    {achievement.description}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AchievementsLayout;
