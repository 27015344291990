import React from 'react';

function getCurrentYear() {
  return new Date().getFullYear();
}

const Footer: React.FunctionComponent = () => (
  <footer className="bg-black">
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            <span className="text-white-50">Filip Snopek</span>
          </div>
          <div className="col-md-12 col-sm-12 text-center">
            <span className="text-white-50">&copy; {getCurrentYear()}</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
