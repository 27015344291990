import React from 'react';

type Tool = {
  id: string;
  name: string;
  percentage: string;
};

type Props = {
  tools: Tool[];
};

const AboutTools: React.FunctionComponent<Props> = ({ tools }) => (
  <div className="pb-4">
    <h6 className="text-center text-md-start fw-bolder">Tools</h6>
    <hr />
    <div className="skills">
      <div className="text-start prog-item">
        {tools.map(tool => (
          <div className="pb-2" key={tool.id}>
            <p>{tool.name}</p>
            <div className="skills-progress pb-2">
              <span data-value={tool.percentage + '%'} style={{ width: tool.percentage + '%' }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default AboutTools;
