import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PageNotFound from './components/404/PageNotFound';
import About from './components/about/About';
import Achievements from './components/achievements/Achievements';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Hero from './components/hero/Hero';
import Navigation from './components/navigation/Navigation';
import Navigation404 from './components/navigation/Navigation404';
import Portfolio from './components/portfolio/Portfolio';
import Skills from './components/skills/Skills';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Navigation />
            <Hero />
            <About />
            <Skills />
            <Achievements />
            <Portfolio />
            <Contact />
          </Route>
          <Route path="*">
            <Navigation404 />
            <PageNotFound />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
