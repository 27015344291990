import React from 'react';

type Language = {
  id: string;
  name: string;
  percentage: string;
};

type Props = {
  languages: Language[];
};

const AboutLanguages: React.FunctionComponent<Props> = ({ languages }) => (
  <div className="pb-4">
    <h6 className="text-center text-md-start fw-bolder">Languages</h6>
    <hr />
    <div className="skills">
      <div className="text-start prog-item">
        {languages.map(language => (
          <div className="pb-2" key={language.id}>
            <p>{language.name}</p>
            <div className="skills-progress pb-2">
              <span
                data-value={language.percentage + '%'}
                style={{ width: language.percentage + '%' }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default AboutLanguages;
