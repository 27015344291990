import React from 'react';

import AboutCode from './AboutCode';
import AboutIntro from './AboutIntro';
import AboutLanguages from './AboutLanguages';
import AboutTools from './AboutTools';

function About() {
  return (
    <section id="about" data-bs-target="#navNavbar">
      <div className="container">
        <div className="row">
          <div className="text-center pb-5">
            <h5 className="fw-bolder section-title">
              About me
            </h5>
          </div>
        </div>
        <div className="row">
          <AboutIntro />
          <div className="col-lg-6 col-md-12 ps-lg-5">
            <div>
              <AboutTools
                tools={[
                  {
                    id: '1',
                    name: 'Cypress',
                    percentage: '80',
                  },
                  {
                    id: '2',
                    name: 'TeamCity',
                    percentage: '70',
                  },
                  {
                    id: '3',
                    name: 'Postman',
                    percentage: '70',
                  },
                  {
                    id: '4',
                    name: 'Fiddler',
                    percentage: '70',
                  },
                  {
                    id: '5',
                    name: 'Jira',
                    percentage: '90',
                  },
                  {
                    id: '6',
                    name: 'HP-ALM',
                    percentage: '85',
                  },
                ]}
              />
              <AboutCode
                programingLanguage={[
                  {
                    id: '1',
                    name: 'HTML',
                    percentage: '90',
                  },
                  {
                    id: '2',
                    name: 'CSS',
                    percentage: '90',
                  },
                  {
                    id: '3',
                    name: 'JavaScript',
                    percentage: '70',
                  },
                ]}
              />
              <AboutLanguages
                languages={[
                  {
                    id: '1',
                    name: 'English',
                    percentage: '95',
                  },
                  {
                    id: '2',
                    name: 'German',
                    percentage: '70',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
