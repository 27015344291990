import React from 'react';

import ButtonSocial from './ButtonSocial';
import TypewriterText from './TypewriterText';

const Hero: React.FunctionComponent = () => (
  <div id="home" data-bs-target="#navNavbar" className="bg-light-gray padding-100px-top">
    <div className="container">
      <div className="row">
        <div className="col-md-7 col-sm-12 order-2 order-md-1 xs-padding-50px-tb">
          <div className="padding-twenty-top sm-padding-five-top xs-no-padding">
            <p className="display-6 text-lg-start text-center">Welcome, I&apos;m</p>
            <h4 className="text-uppercase text-lg-start text-center fw-bold">Filip Snopek</h4>
            <hr className="col-lg-6 col-12" />
            <h5 aria-label="Profession QA Engineer" className="text-lg-start text-center fw-light">
              <TypewriterText />
            </h5>
            <hr className="col-lg-6 col-12" />
            <ButtonSocial
              social={[
                {
                  id: '1',
                  icon: 'facebook',
                  url: 'https://www.facebook.com/filip.snopek.svk',
                },
                {
                  id: '2',
                  icon: 'instagram',
                  url: 'https://www.instagram.com/snopek.filip/',
                },
                {
                  id: '3',
                  icon: 'linkedin',
                  url: 'https://www.linkedin.com/in/filipsnopek/',
                },
              ]}
            />
          </div>
        </div>
        <div className="col-md-5 col-sm-12 order-1 order-md-2 text-right xs-border-bottom border-color-medium-gray xs-padding-nineteen-half-lr">
          <img src="img/profile-picture.webp" alt="Filip Snopek" width="445" height="573" />
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
