import React from 'react';

type Skill = {
  id: string;
  position: string;
  company: string;
  month: string;
  year: string;
  description: string[];
};

type Props = {
  skills: Skill[];
};

const SkillsLayout: React.FunctionComponent<Props> = ({ skills }) => (
  <section id="skills" data-bs-target="#navNavbar" className="bg-light-gray">
    <div className="container">
      <div className="row">
        <div className="text-center pb-5">
          <h5 className="fw-bolder section-title">Skills</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline">
            {skills.map(skill => (
              <div key={skill.id} className="timeline">
                <div className="icon" />
                <div className="date-content">
                  <div className="date-outer">
                    <span className="date">
                      <span className="month">{skill.month}</span>
                      <span className="year">{skill.year}</span>
                    </span>
                  </div>
                </div>
                <div className="timeline-content">
                  <h5 className="title">{skill.position}</h5>
                  <h6>{skill.company}</h6>
                  <ul className="list-group text-start">
                    {skill.description.map((value, index) => (
                      <li className="list-group-item" key={index}>
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SkillsLayout;
