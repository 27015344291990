import React from 'react';

import SkillsLayout from './SkillsLayout';

function Skills() {
  return (
    <SkillsLayout
      skills={[
        {
          id: '1',
          position: 'Web Automation Lead',
          company: 'Erste Digital',
          month: 'October',
          year: '2022',
          description: [
            'Setting up CI/CD in TeamCity',
            'Maintaining Cypress framework',
            'Collaborating with development teams in order to integrate automation into the development process',
            'Ensuring proper integration with other tools and technologies',
            'Monitoring and improving automation processes and techniques',
            'Ensuring clear communication of maintenance schedule, scope of work, and contingency plans to relevant parties',
          ],
        },
        {
          id: '2',
          position: 'QA Engineer',
          company: 'Erste Digital (former Erste Group IT)',
          month: 'January',
          year: '2019',
          description: [
            'Working in agile squad team',
            'Designing and writing test automation scripts based on Behavior Driven Development with Cucumber ',
            'Using test automation framework Cypress.io with JavaScript/TypeScript',
            'Investigating problems in the software as a result of automated tests',
            'Identifying, recording and tracking bugs in Jira'
          ],
        },
        {
          id: '3',
          position: 'Testing Specialist',
          company: 'Erste Group IT',
          month: 'Dec - Jan',
          year: '2017 - 2019',
          description: [
            'Front-end testing of core functionalities focusing on payments/transfers - web traffic debugging, mocking (Fiddler)',
            'Test plan and test case creation based on User stories defining business requirements (SK,CZ,AT,RO)',
            'Active communication with developers/analysts/POs and local country testers (SK,CZ,AT,RO)',
            'Analyzing of detected bugs with developers/analysts and reporting using Jira',
          ],
        },
        {
          id: '4',
          position: 'QA Specialist',
          company: 'Ferratum Slovakia',
          month: 'Jun - Nov',
          year: '2017 - 2017',
          description: [
            'Preparation of test plan based on business requirements',
            'Analyzing of detected bugs with developers/PM and reporting them using Jira',
            'Creating automated test scripts using Selenium (Java)',
            'Testing of REST web services using Postman',
          ],
        },
        {
          id: '5',
          position: 'IT Tester',
          company: 'AutoVision Slovakia',
          month: 'Dec - Jun',
          year: '2014 - 2017',
          description: [
            'Preparation of test plan based on business requirements',
            'Analyzing of detected bugs with developers/PM and reporting them using Jira',
            'Creating automated test scripts using Selenium (Java)',
            'Testing of REST web services using Postman',
          ],
        },
      ]}
    />
  );
}

export default Skills;
