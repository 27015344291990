import React from 'react';

const Contact: React.FunctionComponent = () => (
  <section id="contact" data-bs-target="#navNavbar" className="bg-light-gray">
    <div className="container">
      <div className="row">
        <div className="text-center pb-5">
          <h5 className="fw-bolder section-title">Contact</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <form id="contactForm" method="post" className="contact-form" action="https://formspree.io/f/xrgrgdda">
            <div className="row">
              <div className="col-md-6">
                <label id="contact-label-name" className="text-start" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  className="medium-input"
                  maxLength={50}
                  placeholder="John Doe"
                  required
                  id="name"
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <label id="contact-label-email" className="text-start" htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  className="medium-input"
                  maxLength={70}
                  placeholder="john.doe@example.com"
                  required
                  id="email"
                  name="email"
                />
              </div>
              <div className="col-md-12">
                <label
                  id="contact-label-message"
                  className="text-start"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="big-textarea"
                  rows={6}
                  maxLength={1000}
                  placeholder="Hello..."
                  required
                  id="message"
                  name="message"
                />
              </div>
              <div className="col-md-12 text-center">
                <button id="contact-button-submit" className="btn white" type="submit">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
